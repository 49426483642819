import Swiper from 'swiper/bundle';

$(document).ready(() => {
  new Swiper('.swiper', {
    autoplay: {
      delay: 6000
    },
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',

    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    }
  });
});